$textLight: #ffffff;
$textDark: #2B2B6B;

$grey: #E9E9F0;
$greyLight: #EEEEF3;

$purpleDark: #2B2B6B;
$purpleLight: #E5DBF8;
$purpleVeryLight: #F2EDFC;
$purple: #CBB6F1;

$blueDark: #1B65CE;
$blueLight: #8DB2E7;

$pinkTitle: #FEBBCE;
$pink2: #FDD0CF;
$pinkLight: #FFE8EE;

$pinkGradient1: #EF8BA7;
$pinkGradient2: #FEA3BC;
$pinkGradient3: #FC9FB9;
$pinkGradient4: #D96B8A;


$label1: #2B2B6B;
$label2: #1B65CE;
$label3: #CBB6F1;
$label4: #F9413F;
$label5: #FDA4BD;