@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	position: relative;
	height: 100%;
	width: 100%;
	@include flex('center', 'center');

	.LandingPage-logo {
		position: absolute;
		top: 1.18em;
		right: 1.25em;
	}

	.LandingPage-title {
		position: absolute;
		top: 10em;
		text-align: left;
		width: 12em;
		span {
			display: inline-block;
			font-size: 2.2em;
			line-height: 1;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	.LandingPage-startBtn {
		width: 11.56em;
		height: 3em;
	}

	&.landscape {
		.LandingPage-logo {
			top: 4em;
			right: 3.75em;
		}
		.LandingPage-title {
			width: 34em;
			top: 4.25em;
			left: 3.75em;
			span {
				font-size: 5.625em;
				line-height: 0.89;
			}
		}
		.LandingPage-startBtn {
			top: auto;
			bottom: 11.5625em;
			width: 17.19em;
			height: 4.63em;
			.Button span {
				font-size: 1.5em;
			}
		}
	}
}
