@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.DonePage {
	position: relative;

	.DonePage-content {
		background-color: white;
		padding: 1em 1.5em 3em 1.5em;
		border-radius: 0.31em;
		color: $textDark;
		.DonePage-title {
			position: relative;
			text-align: center;
			span {
				font-size: 1.25em;
				font-weight: 700;
			}
		}
		.DonePage-image {
			width: 100%;
			height: 5em;
			background-size: contain;
			&.t1-finish {
				background-image: url(../../assets/images/theme-1.svg);
			}
			&.t2-finish {
				background-image: url(../../assets/images/theme-2.svg);
			}
			&.t3-finish {
				height: 1em;
			}
			&.t4-finish {
				background-image: url(../../assets/images/theme-4.svg);
			}
		}
		.DonePage-text {
			position: relative;
			text-align: center;
			span {
				font-size: 1em;
			}
		}
	}

	.DonePage-gameTitle {
		width: 14em;
		text-align: left;
		margin: 2em auto 0 auto;
		span {
			font-size: 2.5em;
			line-height: 1;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	&.landscape {
		padding: 2em 1.5em;
		.DonePage-title {
			span {
				font-size: 1.5em;
			}
			
		}
		.DonePage-text {
			span {
				font-size: 1.25em;
			}
		}
	}
}