@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameStepNav {
	position: absolute;
	top: 1em;
	left: calc(50% - 1em);

	.GameStepNav-toggleBtn {
		width: 2em;
		height: 2em;
		background-image: url('../../../assets/images/icons/icon-menu.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.GameStepNav-items {
		max-height: 20em;
		overflow: auto;
		background: white;
		padding: 0.25em 0.5em;
		border-top-left-radius: 0.5em;
		border-top-right-radius: 0.5em;
		.GameStepNav-item {
			&.prev {background-color: rgba(black, 0.1);}
			&.selected {background-color: rgba(black, 0.2);}
			span {
				font-size: 0.75em;
			}
		}
	}
}
