@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameStepVideo {
	position: relative;
	background-color: white;
	padding: 1em 0;
	border-radius: 0.31em;
	color: $textDark;
	.GameStepVideo-title {
		text-align: center;
		margin-bottom: 2em;
		padding: 0 1em;
		line-height: 1.3;
		span {
			font-size: 1.25em;
			font-weight: 700;
		}
	}

	.GameStepVideo-text {
		padding: 0 1em;
		margin-bottom: 1em;
		p {
			line-height: 1.4;
			&:first-of-type {margin-top: 0;}
			&:last-of-type {margin-bottom: 0;}
		}
	}
	.GameStepVideo-videoWrap {
		position: relative;
		width: 19.5em;
		height: calc(0.5625 * 19.5em);
		margin: 0 auto;
		background-color: $blueDark;
		.GameStepVideo-video {
			width: 100%;
			height: 100%;
			&.loading,
			&.ready,
			&.ended {
				@include opacity(0);
			}
		}
		.GameStepVideo-videoPlayBtn {
			position: absolute;
			top: calc(50% - 2.15em);
			left: calc(50% - 2.15em);
			width: 4.3em;
			height: 4.3em;
			background-image: url('../../../assets/images/icons/icon-play.svg');
			cursor: pointer;	
			&.loading,
			&.buffering {
				cursor: default;
				background-image: url('../../../assets/images/icons/icon-loading.svg');
			}
			&.playing {
				display: none;
			}	
		}
	}

	&.landscape {
		padding: 2em 1.56em;
		// margin: 0 calc(50% - 35em);
		width: 60em;
		.GameStepVideo-title {
			margin-bottom: 0.75em;
			padding: 0 1.25em;
			span {
				font-size: 1.5em;
			}
		}
		.GameStepVideo-text {
			padding: 0 2em;
			p {
				font-size: 1.25em;
			}
		}
		.GameStepVideo-videoWrap {
			width: 45em;
			height: calc(0.5625 * 45em);
			margin: 0 auto;
			.GameStepVideo-video {
				width: 100%;
				height: 100%;
				&.loading,
				&.ready,
				&.ended {
					@include opacity(0);
				}
			}
			.GameStepVideo-videoPlayBtn {
				top: calc(50% - 3.2em);
				left: calc(50% - 3.2em);
				width: 6.4em;
				height: 6.4em;
			}
		}
	}
}