@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ResetPassword {
	position: relative;

	.ResetPassword-form {
		padding: 1.7em 3em;
		.ResetPassword-label {
			span {
				color: $textDark;
				font-size: 0.88em;
			}
		}

		.ResetPassword-input {
			width: 100%;
			height: 2.75em;
			border-radius: 0.25em;
			padding: 0 0.5em;
			color: $textDark;
			background-color: $pink2;
			@include flex('flex-start', 'center');
			&:nth-of-type(1) {
				margin-bottom: 1em;
			}
		}

		.ResetPassword-confirmButton {
			margin: 0.5em 0;
			width: 11.56em;
			height: 3.25em;
		}

		.ResetPassword-backButton {
			cursor: pointer;
			span {
				font-size: 0.88em;
				color: $textDark;
			}
		}

		.ResetPassword-errorMessage {
			height: 1em;
			color: red;
			font-size: 0.8em;
		}
	}
}