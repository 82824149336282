@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Login {
	position: relative;
	height: 100%;
	width: 100%;
	@include flex('center', 'center');

	.Login-title {
		position: absolute;
		width: 12em;
		top: 1.18em;
		left: 1.25em;
		text-align: left;
		span {
			font-size: 2.19em;
			line-height: 1;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}
	
	.Login-wrap {
		width: 21em;
		border-radius: 0.31em;
		background-color: white;
		@include box-shadow(0, 0, 0.375em, 0, rgba(#003581, 0.5));
		&.player {
			margin-top: 4em;
		}
	}

	.Login-logo {
		position: absolute;
		top: 1.18em;
		right: 1.25em;
	}
	&.landscape {
		.Login-title {
			top: 4.25em;
			left: 3.75em;
			width: 34em;
			span {
				font-size: 5.625em;
				line-height: 0.89em;
			}
		}
		.Login-wrap {
			width: 31.25em;
			margin-top: 0em;
			border-radius: 0.65em;
		}
		.Login-logo {
			top: 4em;
			right: 3.75em;
		}
	}
}