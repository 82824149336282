@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupMaxAnswers {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#062B33, 0.2);
	backdrop-filter: blur(1px);
	z-index: 20;
	@include flex('center', 'center');

	.PopupMaxAnswers-content {
		position: relative;
		width: 20em;
		background-color: white;
		border-radius: 0.78em;
		border: solid 0.33em $pinkGradient1;
		padding: 2em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba(black, 0.2));

		.PopupMaxAnswers-closeBtn {
			cursor: pointer;
			position: absolute;
			right: 0.25em;
			top: 0.25em;
			width: 1.75em;
			height: 1.75em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
		}

		.PopupMaxAnswers-text {
			span {
				display: inline-block;
				color: $purpleDark;
				font-size: 1em;
				line-height: 1.3;
				margin: 0;
			}
		}
		.PopupMaxAnswers-buttons {
			margin-top: 2em;
			width: 100%;
			@include flex('center', 'center');
			.PopupMaxAnswers-button {
				width: 10em;
				margin: 0 1em;
				> div span {
					text-transform: uppercase;
				}
			}
		}
	}

	
}