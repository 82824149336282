@import '../../../../styles/fonts';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.MultipleChoice {
	position: relative;
	.MultipleChoice-text {
		margin-bottom: 1.375em;
		font-weight: 500;
		p {
			line-height: 1.3;
			&:first-of-type {
				margin-top: 0;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.MultipleChoice-options {
		.MultipleChoice-option {
			position: relative;
			padding: 0.5em 0.75em;
			border: 0.19em solid $pinkGradient1;
			border-radius: 0.25em;
			margin-bottom: 1.25em;
			@include no-select();
			cursor: pointer;
			p {
				color: $textDark;
				margin: 0;
				line-height: 1.3;
			}	
			&.selected {
				background-color: $pinkLight;
			}
			&:last-of-type {
				margin-bottom: 0;
			}

			.MultipleChoice-checkmark {
				position: absolute;
				top: -1.4em;
				right: -1.3em;
				width: 2.8em;
				height: 2.8em;
				background-image: url('../../../../assets/images/icons/icon-checkmark.svg');
			}
		}
	}


	&.landscape {
		.MultipleChoice-text {
			padding: 0 6.8em;
			margin-bottom: 2.25em;
			p {
				font-size: 1.5em;
				line-height: 1.3;
			}
		}
		.MultipleChoice-options {
			padding: 0 6.75em;
			.MultipleChoice-option {
				padding: 1.25em 1.6em;
				margin-bottom: 1.875em;
				p {
					font-size: 1.3em;
				}	

				.MultipleChoice-checkmark {
					top: -1.65em;
					right: -2.25em;
					width: 4.5em;
					height: 4.5em;
				}
			}
		}
	}
}