@import '../../../../styles/fonts';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Evaluate {
	position: relative;
	border-style: solid;
	border-radius: 0.25em;
	border-color: $pinkLight;
	@include flex('','','column');
	.Evaluate-text {
		padding: 0.75em;
		margin-bottom: 0.25em;
		ol {
			padding-inline-start: 1em;
		}
		p, li {
			line-height: 1.3;
			&:first-of-type {
				margin-top: 0;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.Evaluate-options {
		width: 100%;
		padding-right: 1.25em;
		padding-left: 1.25em;
		background-color: rgba($pinkLight, 0.5);
		.Evaluate-option {
			position: relative;
			padding-top: 1em;
			padding-bottom: 1em;
			color: $purpleDark;
			&:last-of-type {
				margin-bottom: 0;
			}
			@include no-select();
			@include flex('center', 'center');

			&.hasDivider {
				border-top: solid;
				border-color: rgba($pinkTitle, 0.5);
			}
			
			.Evaluate-optionText {
				text-align: center;
				border-radius: 0.2em;
				@include no-select();
				@include flex('flex-start', 'center');
				cursor: pointer;
				span {
					display: inline-block;
					font-size: 0.9em;
					line-height: 1.4;
					font-weight: 600;
					color: $textDark;
				}
			}
			.Evaluate-optionValue {
				width: 1.44em;
				height: 1.44em;
				margin: 0 0.5em;
				border-radius: 0.19em;
				border: 0.125em solid $purpleDark;
				background-color: white;
				padding-top: 0.2em;
				@include flex('center', 'center');
				@include no-select();
				
				&.isTicked {
					background-color: $purpleDark;
					background-image: url(../../../../assets/images/icons/icon-check.svg);
					background-size: 0.87em 0.63em;
					background-position: center;
				}
			}
			.Evaluate-minusBtn,
			.Evaluate-plusBtn {
				width: 2.1em;
				height: 2.1em;
				cursor: pointer;
			}
			.Evaluate-minusBtn {
				@include opacity(0.6);
				background-image: url('../../../../assets/images/icons/icon-minus.svg');
			}
			.Evaluate-plusBtn {
				background-image: url('../../../../assets/images/icons/icon-plus.svg');
			}
		}


	}

	&.evaluate-1 {
		@include flex('space-between', 'center');
		margin-top: 1.8em;
		
		.Evaluate-options { 
			.Evaluate-option {
				.Evaluate-optionText {
					width: 12em;
					text-align: left;
				}
			}	
		}

		&.position-0,
		&.position-1,
		&.position-2,
		&.position-3,
		&.position-4 {
			margin: 0;
		}

		&.position-0 {
			border-bottom: none;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		&.position-1,
		&.position-2,
		&.position-3 {
			border-top: none;
			border-bottom: none;
			border-radius: 0;
		}
		&.position-4 {
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		&.position-1,
		&.position-2,
		&.position-3,
		&.position-4 {
			.Evaluate-options { 
				.Evaluate-option {
					border-top: solid;
					border-color: rgba($pinkTitle, 0.5);
				}
			}
		}
	}

	&.evaluate-2 {
		&.multiple {
			border: 0.19em solid $pinkLight;
			border-radius: 0.25em;
			margin-bottom: 1.25em;
		}
		.Evaluate-options { 
			.Evaluate-optionText {
				width: 10em;
				height: 2em;
			}
		}
	}

	&.landscape {
		margin-left: 2.125em;
		margin-right: 3.19em;
		@include flex('', '');
		.Evaluate-text {
			width: calc(100% - 20em);
			margin: 0;
			padding: 1.75em;
			p, li {
				font-size: 1.25em;
				font-weight: 600;
			}
		}
		.Evaluate-options {
			width: 20em;
			margin: auto;
			padding: 1.25em 1.75em 1.25em 1.25em;
			.Evaluate-option {
				position: relative;
				&.text {
					@include flex('flex-end', 'center');
					span {
						font-size: 1.125em;
					}
				}
				.Evaluate-optionText {
					margin-right: 1em;
				}
				.Evaluate-optionValue {
					width: 2.75em;
					height: 2.25em;
				}
				.Evaluate-minusBtn,
				.Evaluate-plusBtn {
					width: 2.25em;
					height: 2.25em;
				}
			}
		}

		&.evaluate-1 {
			margin-top: 0em;
		}
	}
}