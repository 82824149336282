@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Result {
	position: relative;
	width: 75em;
	height: 40em;
	padding: 2em 1.56em 1.56em 1.56em;
	border-radius: 0.625em;
	background-color: white;
	text-align: left;
	@include box-shadow(0, 0, 0.375em, 0, rgba(#003581, 0.5));
	&.sum-each.questions-5 {
		width: 92.8em;
	}
	.Result-text {
		color: $purpleDark;
		text-align: center;
		margin-bottom: 1.25em;
		span {
			display: inline-block;
			font-size: 1.875em;
			font-weight: 700;
			line-height: 1;
		}			
	}
	.Result-result {
		width: 100%;
		&.sum-all {
			width: 100%;
			height: 32em;
			background-color: $pinkLight;
			border-radius: 0.625em;
			padding: 0em 2.375em 0em 2.375em;
			@include flex('space-between', 'center');
			.Result-rowChart {
				width: 100%;
				.Result-rowChartQuestion {
					@include flex('space-between', 'center');
					margin-bottom: 2em;
					&:last-of-type {margin: 0;}
					.Result-rowChartLabel {
						width: 23em;
						text-align: left;
						line-height: 1.33;
						span {
							font-weight: 700;
							color: $textDark;
						}
					}
					.Result-rowChartResultWrap {
						position: relative;
						width: 42.5em;
						height: 3.125em;
						.Result-rowChartResult {
							position: relative;
							height: 100%;
							&.label-1 {background-color: $label1;}
							&.label-2 {background-color: $label2;}
							&.label-3 {background-color: $label3;}
							&.label-4 {background-color: $label4;}
							&.label-5 {background-color: $label5;}
						}
						.Result-rowChartResultValue {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							padding-left: 2em;
							padding-top: 0.4em;
							@include flex('flex-start', 'center');
							span {
								display: inline-block;
								font-size: 1.44em;
								font-weight: 700;
								line-height: 1;
								color: white;
							}
						}
					}
				}
			}
			.Result-pieChart {
				width: 29em;
				height: 29em;

			}
			.Result-labels {
				width: 28.25em;
				.Result-label {
					position: relative;
					padding-left: 3.7em;
					margin-bottom: 1.5em;
					line-height: 1.33;
					padding-top: 0.2em;
					min-height: 2.66em;
					span {
						color: $textDark;
						font-weight: bold;
					}
					&::before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						width: 2.19em;
						height: 2.19em;
						border-radius: 100%;
					}
					&.label-1::before {background-color: $label1;}
					&.label-2::before {background-color: $label2;}
					&.label-3::before {background-color: $label3;}
					&.label-4::before {background-color: $label4;}
					&.label-5::before {background-color: $label5;}
				}
			}
		}

		&.sum-each {
			.Result-questions {
				@include flex('space-between', 'center');
				.Result-question {
					position: relative;
					width: 17.25em;
					height: 24em;
					border-radius: 0.625em;
					color: $textDark;
					background-color: $pinkLight;
					.Result-questionText {
						padding: 1em 1.5em;
						line-height: 1.33;
						span {
							font-weight: 700;
						}
					}
					.Result-pieChart {
						position: absolute;
						width: 15.5em;
						height: 15.5em;
						left: calc(50% - 7.75em);
						bottom: 1.75em;
					}
				}	
			}
			.Result-labels {
				width: 15em;
				margin-top: 2em;
				margin-left: 2em;
				.Result-label {
					position: relative;
					padding-left: 1.65em;
					padding-top: 0.1em;
					line-height: 1;
					height: 2em;
					text-align: left;
					span {
						color: $textDark;
						font-weight: bold;
					}
					&::before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						width: 1em;
						height: 1em;
						border-radius: 100%;
					}
					&.label-1::before {background-color: $label1;}
					&.label-2::before {background-color: $label2;}
					&.label-3::before {background-color: $label3;}
					&.label-4::before {background-color: $label4;}
					&.label-5::before {background-color: $label5;}
				}
			}
		}
	}
	.Result-closeBtn {
		position: absolute;
		right: 1em;
		top: 1em;
		width: 2em;
		height: 2em;
		background-image: url('../../../assets/images/icons/icon-close.svg');
		cursor: pointer;
		&:hover {
			@include scale(1.1);
		}
	}

}
