@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameEnd {
	position: relative;
	min-height: 100%;
	padding-top: 2.4em;

	.GameEnd-gameTitle {
		text-align: center;
		cursor: pointer;
		@include no-select();
		span {
			font-size: 2.2em;
			font-weight: 800;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	.GameEnd-content {
		position: relative;
		width: 75em;
		height: 40em;
		margin: 1.25em auto 0 auto;
		@include flex('space-between', 'flex-start');

		.GameEnd-panel {
			
			background-color: white;
			border-radius: 0.625em;
			@include box-shadow(0, 0, 0.375em, 0, rgba(#003581, 0.5));

			.GameEnd-title {
				text-align: center;
				span {
					color: $textDark;
					font-size: 1.875em;
					font-weight: bold;
				}
			}
			.GameEnd-text {
				width: 100%;
				height: 32.375em;
				background-color: $pinkLight;
				border-radius: 0.625em;
				text-align: left;
				padding: 4.5em;
				li {
					display: inline-block;
					line-height: 2;
					color: $textDark;
					font-size: 1.5em;
					font-weight: bold;
					margin: 1em 0;
				}
			}
			.GameEnd-resultWrap {
				position: relative;
				width: 100%;
				height: 17em;
				background-color: $pinkLight;
				border-radius: 0.625em;
				@include flex('flex-start', 'center', 'column');
				.GameEnd-resultIcon {
					&.result-1 {
						margin-top: 1.875em;
						width: 14.8em;
						height: 9.13em;
						background-image: url('../../assets/images/icons/icon-result-1.svg');
					}
					&.result-2 {
						margin-top: 0.81em;
						width: 11.94em;
						height: 11.25em;
						background-image: url('../../assets/images/icons/icon-result-2.svg');
					}
				}
				.GameEnd-resultBtn {
					position: absolute;
					bottom: 1em;
					display: inline-block;
					width: 14.375em;
					height: 3em;
				}
			}
			

			&.text {
				padding: 2em 1.56em 0 1.56em;
				width: 55em;
				height: 100%;
			}
			&.result {
				padding: 1.1em 1.44em 0 1.44em;
				width: 19.3em;
				height: 19.3em;
				&:first-of-type {
					margin-bottom: 1.4em;
				}
			}
			
		}





		
	}

	.GameEnd-prevBtn,
	.GameEnd-nextBtn {
		position: absolute;
		margin-top: 1.25em;
		width: 11.56em;
		height: 3em;
	}

	.GameEnd-prevBtn {
    left: calc(50% - 37.5em);
  }
  .GameEnd-nextBtn {
    right: calc(50% - 37.5em);
  }



}
