@mixin fadeIn($seconds: 0.5s, $delay: 0s) {
	-webkit-animation: fadeIn $seconds $delay ease-out forwards;
	animation: fadeIn $seconds $delay ease-out forwards;
  transform-origin: center center;
}

@-webkit-keyframes fadeIn {
	0% {
    @include opacity(0);
  }
	100% {
    @include opacity(1);
  }
}
@keyframes fadeIn {
	0% {
    @include opacity(0);
  }
	100% {
    @include opacity(1);
  }
}

/* SHAKE */
@mixin shake($duration: 0.1s, $delay: 0s, $repeat: 10) {
  -webkit-animation: shake $duration ease-in-out $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: shake $duration ease-in-out $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  100% {@include translate(0, 0);}
}
@keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  0%, 100% {@include translate(0, 0);}
}