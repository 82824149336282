@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameInfo {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 1em;
	@include flex('center', 'center', 'column');

	.GameInfo-gameTitle {
		position: absolute;
		width: 12em;
		top: 1.18em;
		left: 1.25em;
		cursor: pointer;
		@include no-select();
		span {
			display: inline-block;
			font-size: 2.5em;
			line-height: 1;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	.GameInfo-content {
		position: relative;
		width: 83em;
		height: 35em;
		border-radius: 0.625em;
		background-color: white;
		padding: 1.75em 3.34em;
		.GameInfo-title {
			text-align: center;
			p {
				color: $textDark;
				font-size: 1.875em;
				font-weight: bold;
				line-height: 1.1;
			}
		}
		.GameInfo-text {
			text-align: center;
			width: 47em;
			margin: 3.9em auto 0 auto;
			p {
				color: $textDark;
				font-size: 1.56em;
				font-weight: 300;
				margin: 0;
				line-height: 1.5;
			}
		}

		.GameInfo-process {
			position: relative;
			width: 76.44em;
			height: 12.875em;
			margin: 3.5em auto 0.5em auto;
			background-image: url('../../assets/images/backgrounds/background-process.svg');
			@include flex('space-between', 'flex-start');

			.GameInfo-processStep {
				position: relative;
				width: 15.288em;
				text-align: center;
				padding-top: 1.5em;
				span {
					display: inline-block;
					width: 100%;
					color: $textDark;
					&:nth-of-type(1) {
						font-size: 5em;
						line-height: 1;
						font-weight: 600;
					}
					&:nth-of-type(2) {
						width: 7em;
						margin-top: 1em;
						font-size: 1.43em;
						font-weight: 500;
						line-height: 1.4;
					}
				}
				
			}
		}
	}

	.GameInfo-nextBtn {
		position: absolute;
		top: calc(50% + 20em);
		left: calc(50% + 29.9em);
		width: 11.6em;
		height: 3.125em;
		
	}

}
