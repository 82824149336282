@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CreateUser {
	position: relative;

	.CreateUser-form {
		padding: 1.7em 3em;
		.CreateUser-label {
			span {
				color: $textDark;
				font-size: 0.88em;
			}
		}

		input {
			width: 100%;
			height: 2.75em;
			border-radius: 0.25em;
			padding: 0 0.5em;
			color: $textDark;
			background-color: $pink2;
			@include flex('flex-start', 'center');
			&:nth-of-type(1),
			&:nth-of-type(2) {
				margin-bottom: 1em;
			}
		}

		.CreateUser-button {
			margin: 1em auto 0 auto;
			width: 11.56em;
			height: 3.25em;
		}

		.CreateUser-errorMessage {
			height: 1em;
			color: red;
			font-size: 0.8em;
		}
		.CreateUser-backButton {
			cursor: pointer;
			span {
				font-size: 0.88em;
				color: $textDark;
			}
		}
	}
}