@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.CreateGame {
	position: relative;
	width: 100%;
	height: 100%;
	@include flex('center', 'center', 'column');

	.CreateGame-gameTitle {
		display: inline-block;
		position: absolute;
		left: 1.75em;
		top: 1.75em;
		width: 15.75em;
		margin: 0 auto;
		cursor: pointer;
		@include no-select();
		span {
			display: inline-block;
			font-size: 2.5em;
			line-height: 1;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	.CreateGame-manualBtn {
		position: absolute;
		width: 10em;
		height: 3.125em;
		top: 1.375em;
		right: 2.875em;
	}

	.CreateGame-content {
		position: relative;
		width: 73.125em;
		border-radius: 0.625em;
		background-color: white;
		padding: 2.5em 2.875em;
		.CreateGame-title {
			span {
				color: $textDark;
				font-size: 2.5em;
				line-height: 1;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
		.CreateGame-text {
			p {
				text-align: left;
				color: $textDark;
				line-height: 2;
				font-size: 1.25em;
				margin: 0;
			}
		}

		.CreateGame-themes {
			position: relative;
			width: 100%;
			margin-top: 1.5em;
			@include flex('space-between', 'flex-start');

			.CreateGame-theme {
				position: relative;
				width: 15.625em;
				height: 15.5em;
				border-radius: 0.625em;
				padding: 11em 0.9em 1em 1em;
				background-color: $pinkLight;
				border: 0.3em solid $pinkLight;
				background-size: 9.375em auto;
				background-position: top 1em center;
				@include transition(0.1s, 'transform');
				cursor: pointer;
				&.theme-1 {background-image: url('../../assets/images/theme-1.svg');}
				&.theme-2 {background-image: url('../../assets/images/theme-2.svg');}
				&.theme-3 {background-image: url('../../assets/images/theme-3.svg');}
				&.theme-4 {background-image: url('../../assets/images/theme-4.svg');}
				&.selected {
					border-color: $purpleDark;
				}
				&:hover {
					@include scale(1.05);
				}

				&.loading {
					@include opacity(0.5);
					cursor: wait;
				}
				
				.CreateGame-themeName {
					position: relative;
					text-align: center;
					line-height: 1.3;
					margin-top: 0.4em;
					@include no-select();

					span {
						font-size: 1.1em;
						color: $textDark;
						font-weight: 700;
						text-transform: uppercase;
					}
				}
			}
		}
	}

	.CreateGame-feedback {
		@include flex('center', 'center');
		height: 2em;
		span {
			color: red;
		}
	}
	
	.CreateGame-buttons {
		margin-top: 1.25em;
		@include flex();

		.CreateGame-button {
			width: 11.6em;
			height: 3.125em;
			margin: 0 1em;
		}
	}

	.CreateGame-logoutBtn {
		position: absolute;
		bottom: 1em;
		left: 1em;
		width: 6em;
		height: 2.5em;
	}
}