@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameStepText {
	position: relative;
	background-color: white;
	padding: 1em 1.5em;
	color: $textDark;
	border-radius: 0.625em;
	.GameStepText-title {
		text-align: center;
		margin-bottom: 1em;
		span {
			font-size: 1.25em;
			font-weight: 700;
		}
	}
	.GameStepText-text {
		p {
			line-height: 1.3;
			text-align: center;
			&:first-of-type {
				margin-top: 0;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&.landscape {
		padding: 2em 1.5em;
		width: 40em;
		.GameStepText-title {
			margin-bottom: 0.75em;
			padding: 0 1.25em;
			span {
				font-size: 1.5em;
			}
		}
		.GameStepText-text {
			p {
				font-size: 1.25em;
			}
		}
	}
}