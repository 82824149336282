@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.LoginFacilitator {
	position: relative;
	.LoginFacilitator-loginForm {
		padding: 1.7em 3em;
		.LoginFacilitator-label {
			span {
				color: $textDark;
				font-size: 0.88em;
			}
		}
		.LoginFacilitator-input {
			width: 100%;
			height: 2.75em;
			border-radius: 0.25em;
			padding: 0 0.5em;
			color: $textDark;
			background-color: $pink2;
			@include flex('flex-start', 'center');
			&:nth-of-type(1) {
				margin-bottom: 1em;
			}
		}
		.LoginFacilitator-resetPassword {
			width: 100%;
			margin: 1em 0;
			cursor: pointer;
			@include flex('center', 'center');
			span {
				color: $textDark;
				font-size: 0.88em;
			}
		}
		.LoginFacilitator-buttons {
			@include flex('space-between');

			.LoginFacilitator-button {
				width: 11.56em;
				height: 3.125em;
			}
		}
	}
}