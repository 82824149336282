@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameQuote {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 1em;
	@include flex('center', 'center', 'column');

	.GameQuote-gameTitle {
		position: absolute;
		width: 12em;
		top: 1.18em;
		left: 1.25em;
		cursor: pointer;
		@include no-select();
		span {
			display: inline-block;
			font-size: 2.5em;
			line-height: 1;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	.GameQuote-content {
		position: relative;
		width: 54em;
		.GameQuote-image {
			width: 100%;
			height: 13.5em;
			background-size: contain;
			&.theme-1 {
				background-image: url('../../assets/images/theme-1.svg');
			}
			&.theme-2 {
				background-image: url('../../assets/images/theme-2.svg');
			}
			&.theme-3 {
				background-image: url('../../assets/images/theme-3.svg');
			}
			&.theme-4 {
				background-image: url('../../assets/images/theme-4.svg');
			}
		}
		.GameQuote-text {
			width: 100%;
			margin-top: 2.215em;
			p {
				text-align: center;
				color: white;
				font-size: 2.2em;
				font-weight: 500;
				margin: 0;
				line-height: 1.125;
			}
		}

	}

	.GameQuote-nextBtn {
		position: absolute;
		top: calc(50% + 20em);
		left: calc(50% + 29.9em);
		width: 11.6em;
		height: 3.125em;
		
	}

}
