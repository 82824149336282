@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Settings {
	position: relative;
	width: 100%;
	height: 100%;
	@include flex('space-around', 'center', 'column');
	.Settings-logoutBtn {
		position: absolute;
		bottom: 1em;
		left: 1em;
		width: 6em;
		height: 2.5em;
	}

	.Settings-logo {
		position: absolute;
		top: 4em;
		right: 3.75em;
	}

	.Settings-title {
		position: absolute;
		width: 34em;
		top: 4.25em;
		left: 3.75em;
		span {
			display: inline-block;
			font-size: 5.625em;
			line-height: 0.89;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	.Settings-content {
		position: relative;
		width: 31.25em;
		height: 20.375em;
		padding: 5em 3.875em 1.81em 3.875em;
		border-radius: 0.65em;
		background-color: white;
		@include box-shadow(0, 0, 0.375em, 0, rgba(#003581, 0.5));

		.Settings-text {
			text-align: center;

			span {
				display: inline-block;
				font-size: 1.25em;
				line-height: 1.625;
			}
		}

		.Settings-buttons {
			position: absolute;
			right: 2.9em;
			left: 2.9em;
			bottom: 1.81em;
			@include flex('space-between');

			.Settings-button {
				width: 11.56em;
				height: 3.125em;
			}
		}

		.Settings-feedback {
			span {
				color: red;
				font-size: 0.7em;
			}
		}

		&.newUser .Settings-buttons {
			@include flex('center');
		}
	}
}