@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Logo {
	position: relative;
	width: 6.25em;
	height: 6.25em;
	background-image: url('../../../assets/images/logo.svg');

	&.landscape-login {
		width: 12.9em;
		height: 12.9em;
	}
}