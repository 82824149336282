@import 'styles/fonts';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/animations';
html {
  position: relative;
  font-size: calc(16 * ((100vw / 375)));
  height: 100%;
}

body {
  position: relative;
	height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'poppins';
  font-size: 1em;
  font-weight: 400;
  overflow: hidden;
}

#root {
  height: 100%;
  overflow: hidden;
}

*,
*::after,
*::before {
	box-sizing: border-box;
  font-family: 'poppins';
  font-weight: 500;
  background-size: contain;
	background-repeat: no-repeat;
  background-position: center center;
}

button {
  border: none;
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

ul, ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
  color: inherit;
  font-size: inherit;
  border-style: none;
  outline: none;
  box-shadow: none;
  @include appearance(none);
}

// Landscape
@media (orientation: landscape) {
  body {
    font-size: calc(16 * ((100vw / 1536)));
  }
}

// Landscape wider than 16/9
@media (min-aspect-ratio: 16/9) {
  body {
    font-size: calc(16 * ((100vh / 864)));
  }
}