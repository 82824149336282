@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameStepQuestions {
	position: relative;
	overflow: auto;
	background-color: white;
	padding: 1em 1.25em 1.5em 1.25em;
	color: $textDark;
	border-radius: 0.31em;
	.GameStepQuestions-currentMember {
		text-align: right;
		margin-bottom: 0.81em;
		text-align: center;
		span {
			font-weight: 800;
			font-size: 0.8em;
		}
	}
	.GameStepQuestions-title {
		position: relative;
		text-align: center;
		line-height: 1.3;
		span {
			font-size: 1.25em;
			font-weight: 700;
		}
	}
	.GameStepQuestions-subText {
		margin-top: 1em;
		position: relative;
		text-align: center;
		line-height: 1.3;
		span {
			font-size: 1em;
		}
	}
	.GameStepQuestions-questions {
		position: relative;
		margin-top: 1em;
	}

	&.landscape {
		padding: 2em 1.5em;
		width: 50em;
		margin: 0 auto;
		.GameStepQuestions-title {
			margin-bottom: 0.75em;
			span {
				font-size: 1.5em;
			}
		}
		.GameStepQuestions-subText {
			span {
				font-size: 1.25em;
			}
		}
	}
}