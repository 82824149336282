@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Game {
	position: relative;
	height: 100%;

	.Game-group {
		position: absolute;
		top: 0.5em;
		right: 0em;
		width: 4.3em;
		padding-top: 3.4em;
		background-image: url('../../assets/images/icons/icon-group.svg');
		background-size: 3em auto;
		background-position: top 0.5em left 0.5em;
		text-align: center;
		white-space: nowrap;
		cursor: pointer;
		.Game-groupPlayers {
			position: absolute;
			top: 0.15em;
			right: 0.5em;
			width: 1.2em;
			height: 1.2em;
			border-radius: 100%;
			background-color: $blueDark;
			@include flex('center', 'center');
			span {
				color: white;
				font-size: 0.625em;
				font-weight: 700;
			}
		}
		.Game-groupName {
			padding-right: 0.3em;
			span {
				font-size: 0.625em;
				font-weight: 700;
				color: white;
			}
		}
	}

	.Game-logoutBtn {
		position: absolute;
		top: 1.18em;
		left: 1.25em;
		width: 5em;
		height: 2em;	
	}

	.Game-content {
		position: relative;
		top: 6em;
		height: calc(100% - (6em + 5em));
		overflow: auto;
		padding: 0 1em 0 1em;
		&.video,
		&.multiple-choice,
		&.text,
		&.finish {
			@include flex('center', 'flex-start');
		}
	}
			
	.Game-footer {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 5em;
		padding: 1em;

		.Game-prevBtn,
		.Game-nextBtn {
			position: absolute;
			width: 10em;
			height: 3em;	
		}
		.Game-prevBtn {
			left: 1em;
		}
		.Game-nextBtn {
			right: 1em;
		}
	}

	.Game-evalPopup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(#062B33, 0.2);
		backdrop-filter: blur(1px);
		z-index: 20;
		@include flex('center', 'center');

		.Game-evalPopupWindow {
			width: 20em;
			background-color: white;
			border-radius: 0.78em;
			border: solid 0.33em $pinkGradient1;
			padding: 2em;
			@include flex('space-between', 'center', 'column');
			@include box-shadow(0, 0.15em, 0.67em, 0, rgba(black, 0.2));

			.Game-evalPopupText {
				text-align: center;
				margin-bottom: 1em;

				span {
					color: $textDark;
					font-size: 1em;
					line-height: 1.3;
					margin: 0;
				}
			}

			.Game-evalPopupButtons {
				margin-top: 2em;
				width: 100%;
				@include flex('center', 'center');

				.Game-evalPopupButton {
					width: 10em;
					margin: 0 1em;
					> div span {
						text-transform: uppercase;
					}
				}
			}
		}
	}

	&.landscape {
		.Game-group { 
			top: 1em;
			right: 3em;
			width: 6.3em;
			padding-top: 6em;
			background-size: 5em auto;
			background-position: top 0.5em left 0.5em;
			.Game-groupPlayers {
				top: 0em;
				right: 0.5em;
				width: 1.9em;
				height: 1.9em;
				span {
					font-size: 1em;
				}
			}
			.Game-groupName {
				padding-right: 0.3em;
				span {
					font-size: 1em;
				}
			}	
		}
		.Game-content {
			top: 8.5em;
			height: calc(100% - (8.5em + 6em)); // substract height of header and footer
		}
		.Game-footer {
			padding: 1em 0;
			&.video {
				width: 60em;
				left: calc(50% - 30em);
				.Game-nextBtn {
					right: 0;
				}
			}
			&.text,
			&.questions {
				width: 60em;
				left: calc(50% - 30em);
				.Game-prevBtn {
					left: 0;
				}
				.Game-nextBtn {
					right: 0;
				}
			}
			&.text {
				width: 40em;
				left: calc(50% - 20em);
			}
		}
	}
}