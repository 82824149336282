@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupResult {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#062B33, 0.2);
	backdrop-filter: blur(1px);
	@include flex('center', 'center');

	.PopupResult-content {
		position: relative;
		border-radius: 0.625em;
		background-color: white;
		@include box-shadow(0, 0, 0.375em, 0, rgba(#003581, 0.5));
		.PopupResult-closeBtn {
			position: absolute;
			right: 1em;
			top: 1em;
			width: 2em;
			height: 2em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
			cursor: pointer;
			&:hover {
				@include scale(1.1);
			}
		}
	}
}