@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameStatus {
	position: relative;
	min-height: 100%;
	@include flex('center', 'center');

	&.hasGroups {
		background-color: $blueDark;
	}

	.GameStatus-gameTitle {
		display: inline-block;
		position: absolute;
		left: 1.75em;
		top: 1.75em;
		width: 15.75em;
		margin: 0 auto;
		cursor: pointer;
		@include no-select();
		span {
			display: inline-block;
			font-size: 2.5em;
			line-height: 1;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	.GameStatus-content {
		position: relative;
		width: 72.8em;

		.GameStatus-title {
			text-align: center;
			margin-bottom: 1em;
			width: 57em;
			margin: 0 auto;
			span {
				font-size: 1.875em;
				font-weight: 500;
				line-height: 1.1;
				color: white;
			}
		}

		.GameStatus-groups {
			position: relative;
			width: 100%;
			height: 17.68em;
			text-align: center;
			overflow: auto;
			white-space: nowrap;
			margin-top: 4em;
			.GameStatus-placeholder {
				height: 100%;
				width: 100%;
				@include flex('center');
				.GameStatus-placeholderGroup {
					width: 13.5625em;
					height: 100%;
					display: inline-block;
					margin-right: 1em;
					padding-top: 12.5em;
					border-radius: 0.625em;
					background-color: white;
					background-image: url('../../assets/images/icons/icon-new-group.svg'), linear-gradient(rgba($pinkGradient1, 0), rgba($pinkGradient1, 1));
					background-size: 10em auto, 100% 100%;
					background-position: top 1.6em center, center center;
					@include opacity(0.3);
				}
				.GameStatus-placeholderText {
					position: absolute;
					bottom: 2.6em;
					span {
						color: white;
						font-size: 1.875em;
						font-weight: bold;
					}

				}
			}

			.GameStatus-group {
				position: relative;
				width: 13.5625em;
				height: 100%;
				display: inline-block;
				margin-right: 1em;
				padding-top: 12.5em;
				border-radius: 0.625em;
				background-color: white;
				background-image: url('../../assets/images/icons/icon-group.svg'), linear-gradient(rgba($pinkGradient1, 0), rgba($pinkGradient1, 1));
				background-size: 10em auto, 100% 100%;
				background-position: top 1.6em center, center center;
				.GameStatus-groupName {
					white-space: nowrap;
					overflow: hidden;
					text-align: center;
					span {
						font-size: 1em;
						font-weight: 500;
					}
				}
				.GameStatus-groupProgressTrack {
					position: absolute;
					bottom: 2.375em;
					left: 1.74em;
					right: 1.75em;
					height: 0.875em;
					border-radius: 0.5em;
					background-color: white;
					overflow: hidden;
					.GameStatus-groupProgressBar {
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						border-top-left-radius: 0.5em;
						border-bottom-left-radius: 0.5em;
						background-color: $blueDark;
						@include transition(0.5s, width, ease-in-out);
					}
				}
			}
		}

		.GameStatus-qrInfo {
			width: 37.275em;
			margin: 2.5em auto;
			color:white;
			text-align: center;
			line-height: 1.3;
			span {
				font-size: 1.56em;
				font-weight: 500;
			}
		}

		.GameStatus-loginQR {
			position: fixed;
			bottom: 2em;
			left: 2em;
			@include flex('center');
		}
	}

	.GameStatus-nextBtn {
		position: absolute;
		top: calc(50% + 20em);
		left: calc(50% + 29.9em);
		width: 11.6em;
		height: 3.125em;
	}
}