@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameResults {
	position: relative;
	min-height: 100%;
	padding-top: 2.4em;
	text-align: center;
	.GameResults-title {
		text-align: center;
		cursor: pointer;
		@include no-select();
		span {
			font-size: 2.2em;
			font-weight: 800;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}
	.GameResults-content {
		position: relative;
		display: inline-block;
		margin: 1.25em auto 0 auto;
	}
  
  .GameResults-prevBtn,
	.GameResults-nextBtn {
		position: absolute;
		margin-top: 1.25em;
		width: 11.56em;
		height: 3em;
	}
  .GameResults-prevBtn {
    left: calc(50% - 37.5em);
		&.sum-each.questions-5 {
			left: calc(50% - 46.5em);
		}
  }
  .GameResults-nextBtn {
    right: calc(50% - 37.5em);
		&.sum-each.questions-5 {
			right: calc(50% - 46.5em);
		}
  }
}