@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.LoginGroup {
	position: relative;
	.LoginGroup-form {
		padding: 1em 1.125em 3em 1.125em;

		/* Login */
		.LoginGroup-label {
			span {
				color: $textDark;
				font-size: 0.88em;
				font-weight: 500;
			}
		}

		/* Login / enter group name */
		.LoginGroup-input {
			width: 100%;
			height: 2.75em;
			border-radius: 0.25em;
			padding: 0 0.5em;
			color: $textDark;
			background-color: $pink2;
			padding-top: 0.2em;
			@include flex('flex-start', 'center');
			&.name {
				margin: 0 auto 2em auto;
				width: 15em;
				background-color: $pink2;
			}
		}

		/* Login / submit group name */
		.LoginGroup-button {
			margin: 1em auto 0 auto;
			width: 11.56em;
			height: 3.125em;	
		}

		/* Choose group / new group*/
		.LoginGroup-title {
			text-align: center;
			span {
				font-size: 1.25em;
				font-weight: 700;
				color: $textDark;
			}
		}
		.LoginGroup-newGroupBtn {
			margin: 0.93em auto 0 auto;
			height: 8.9em;
			background-image: url('../../../assets/images/icons/icon-new-group.svg');
			background-size: 7.19em auto;
			background-position: top center;
			cursor: pointer;
			@include flex('center', 'flex-end');
			span {
				font-size: 0.88em;
				font-weight: 600;
				color: $textDark;
			}
		}
		.LoginGroup-groups {
			margin-top: 1em;
			max-height: 10em;
			white-space: nowrap;
			overflow: auto hidden;
			@include fancy-scrollbar(100%,0.5em,$blueLight,0.5em, 1em, 1em, $blueDark);
			.LoginGroup-group {
				display: inline-block;
				position: relative;
				width: 7.19em;
				height: 8.94em;
				margin: 0 0.75em;
				background-image: url('../../../assets/images/icons/icon-group.svg');
				background-size: 100% auto;
				background-position: top center;
				&.loading {
					&::after {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						pointer-events: none;
						background-image: url('../../../assets/images/icons/icon-loading.svg');
						background-size: auto 60%;
						background-position: center center;
					}
				}
				span {
					position: absolute;
					display:inline-block;
					width: 100%;
					left: 0;
					bottom: 0;
					color: $purpleDark;
					font-size: 0.88em;
					text-align: center;
					white-space: nowrap;
					overflow: hidden;
				}
			}
		}

		/* New group */
		.LoginGroup-image {
			width: 9.5em;
			height: 9.5em;
			margin: 1em auto; 
			background-image: url('../../../assets/images/icons/icon-group.svg');
		}


		/* Err message */
		.LoginGroup-errMsg {
			height: 0.65em;
			margin-top: 0.5em;
			line-height: 1;
			span {
				color: red;
				font-size: 0.65em;
			}
		}
	} 
	&.groups,
	&.create {
		.LoginGroup-form {
			padding: 1em 1.125em;
		}
	}
}