@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupGroupPlayers {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include flex('center', 'center');

	.PopupGroupPlayers-gameTitle {
		position: absolute;
		width: 12em;
		top: 1.18em;
		left: 1.25em;
		text-align: left;
		span {
			font-size: 2.19em;
			line-height: 1;
			font-weight: 900;
			color: $pinkTitle;
			text-transform: uppercase;
		}
	}

	.PopupGroupPlayers-logo {
		position: absolute;
		top: 1.18em;
		right: 1.25em;
	}

	.PopupGroupPlayers-content {
		position: relative;
		width: 21em;
		margin: 4em auto 0 auto;
		padding: 1em 0 4em 0;
		border-radius: 0.31em;
		background-color: white;
		@include box-shadow(0, 0, 0.375em, 0, rgba(#003581, 0.5));
		.PopupGroupPlayers-title {
			position: relative;
			text-align: center;
			span {
				font-size: 1.25em;
				font-weight: 700;
				color: $textDark;
			}
		}
		.PopupGroupPlayers-image {
			position: relative;
			width: 9.5em;
			height: 9.5em;
			margin: 1em auto; 
			background-image: url('../../../assets/images/icons/icon-group.svg');
		}
		.PopupGroupPlayers-text {
			text-align: center;
			span {
				font-size: 0.875em;
				font-weight: 500;
			}
		}
		.PopupGroupPlayers-players {
			@include flex('center', 'center');
			.PopupGroupPlayers-minusBtn,
			.PopupGroupPlayers-plusBtn {
				width: 2.1em;
				height: 2.1em;
				cursor: pointer;
			}
			.PopupGroupPlayers-minusBtn {
				@include opacity(0.6);
				background-image: url('../../../assets/images/icons/icon-minus.svg');
			}
			.PopupGroupPlayers-plusBtn {
				background-image: url('../../../assets/images/icons/icon-plus.svg');
			}
			.PopupGroupPlayers-value {
				width: 2.6em;
				height: 2.2em;
				margin: 0 0.5em;
				border-radius: 0.25em;
				border: 0.125em solid $pinkGradient1;
				background-color: white;
				padding-top: 0.2em;
				@include flex('center', 'center');
				span {
					font-size: 0.9em;
					font-weight: 600;
					color: $textDark;
				}
			}
		}
	}

	.PopupGroupPlayers-button {
		position: absolute;
		width: 10em;
		height: 3em;	
		right: 1em;
		bottom: 1em;
	}
}