@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CompanyLogos {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 6em;
	width: 100%;
	@include flex('center', 'center');
	.CompanyLogos-logo {
		height: 100%;
		background-position: center center;
		&.egmont {
			width: 5em;
			background-image: url('../../../assets/images/logo-egmont.png');
			background-size: 100% auto;
		}
		&.sko {
			width: 5em;
			background-image: url('../../../assets/images/logo-sko.png');
			background-size: 100% auto;
			margin: 0 2.5em;
		}
		&.cgl {
			display: inline-block;
			@include no-select();
			color: transparent;
			width: 5.5em;
			background-image: url('../../../assets/images/logo-cgl.svg');
			background-size: 100% auto;
		}
	}

	&.landscape {
		height: 8em;
		bottom: 1.75em;
			.CompanyLogos-logo {
				&.egmont {
					width: 12.81em;
				}
				&.sko {
					width: 12.81em;
					margin: 0 2.5em;
				}
				&.cgl {
					width: 13.375em;
				}
			}
	}
}