@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.QR {
	position: relative;
	@include flex('space-between', 'flex-start', 'column');
	.QR-info {
		width: 15em;
		
		.QR-title {
			text-align: center;
			line-height: 1.1;
			span {
				display: inline-block;
				color: white;
				font-size: 2.19em;
				font-weight: 500;
				&:last-of-type {
					font-weight: 800;
				}
			}
		}
	}
	.QR-code {
		width: 15em;
		margin-top: 1.1em;
		svg {
			width: 15em;
			height: auto;
		}
		canvas {
			display: none;
		}
	}
}