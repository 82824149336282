@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.43em;
	border-radius: 0.2em;
	text-align: center;
	text-decoration: none;
	line-height: 1;
	font-size: inherit;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		font-size: inherit;
		font-weight: 600;
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 0.4;
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: right 1em center;
		}
	}


	&.small {
		width: 6em;
		height: 1em;
		span {
			font-size: 0.7em;
		}
	}

	&.fullHeight {
		height: 100%;
	}

	&.pink {
		padding-top: 0.2em;
		background-image: linear-gradient($pinkGradient1 20%, $pinkGradient2 50%, $pinkGradient3 80%, $pinkGradient4 100%);
		span {
			color: $textDark;
		}
	}

	&.purple {
		background-color: $purpleDark;
		span {
			color: $textLight;
		}
	}

	&.white {
		background-color: white;
		span {
			color: $purpleDark;
		}
	}

	&.cookie {
		position: absolute;
		bottom: 1em;
		left: 1em;
		width: 2em;
		height: 2em;
		background-image: url('../../../assets/images/icons/icon-cookie.svg');

		&.landscape {
			bottom: 2em;
			left: 2em;
			width: 3em;
			height: 3em;
		}
	}
}